/**
 * Styles for "cards".
 */

.card {
    @apply px-4 py-5 bg-white shadow rounded-lg;

    &.flush {
        @apply px-0 py-0;
    }

    @screen sm {
        @apply px-6;
    }
}

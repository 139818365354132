/**
 * Form styling.
 */

.btn {
    @apply inline-flex items-center justify-center px-4 py-2 text-sm font-medium
        border border-transparent rounded-md shadow-sm
        transition duration-150 ease-in-out;

    &, &:hover, &:focus, &:active {
        @apply no-underline;
    }

    &:focus {
        @apply outline-none ring-indigo-500;
    }

    .issue-count {
        @apply ml-1 text-xs text-gray-500 font-normal;
    }
}

.btn-primary {
    @apply bg-indigo-600;

    &, &:hover, &:focus, &:active {
        @apply text-white;
    }

    &:hover {
        @apply bg-indigo-500;
    }

    &:focus, &:active {
        @apply bg-indigo-700;
    }

    &:focus {
        @apply border-indigo-700;
    }
}

.btn-secondary {
    @apply text-gray-700 bg-white border-gray-300;

    &:hover {
        @apply bg-gray-50;
    }

    svg {
        @apply text-gray-500;

        width: 1.5em;
        height: 1.5em;
    }

    &.filter-active {
        @apply bg-gray-200;
    }
}

.form-label {
    @apply block mb-1 text-sm font-medium leading-5 text-gray-700;
}

.form-input,
.form-select {
    @apply px-3 py-2 bg-white border-gray-300 rounded-md;

    &::placeholder {
        @apply text-gray-400;
    }

    &:focus {
        @apply ring-blue-600;
    }
}

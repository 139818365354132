@tailwind base;
@tailwind components;
@tailwind utilities;

@import "cards";
@import "forms";
@import "images";
@import "results";
@import "site";
@import "tables";
@import "typography";

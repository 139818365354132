/**
 * Base site typography.
 */

h1 {
    @apply text-2xl font-bold leading-tight transition duration-150 ease-in-out;

    @screen sm {
        @apply text-3xl;
    }
}

a {
    @apply text-indigo-600 transition duration-200 ease-in-out;

    &:hover, &:focus, &:active {
        @apply underline;
    }

    &:hover {
        @apply text-indigo-500;
    }

    &:focus, &:active {
        @apply text-indigo-700;
    }
}

dt {
    @apply font-medium text-gray-600
}

code {
    @apply inline-block px-1 bg-gray-100;

    font-size: 0.86em;
}

// A CSS selector path.
.selector-path {
    @apply inline-block px-1 font-mono bg-gray-100;

    font-size: 0.86em;
}

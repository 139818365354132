/**
 * Styles for the site layout.
 */

body {
    @apply flex flex-col min-h-screen text-gray-900 leading-normal bg-gray-100;
}

.site-content {
    @apply flex-grow mb-10;

    .container {
        @apply max-w-7xl mx-auto px-4 py-6;
    }

    @screen sm {
        @apply px-8;
    }
}

.site-footer {
    @apply px-6 py-8 text-sm text-center text-gray-300 transition-all;

    background: #252f3f;

    a {
        @apply underline text-current;

        &:hover, &:focus, &:active {
            @apply text-gray-100;
        }
    }

    .copyright {
        @apply mt-2 text-xs;
    }

    @screen md {
        @apply py-12;
    }
}

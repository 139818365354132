.list-table {
    @apply min-w-full;

    tr {
        @apply border-gray-200;
    }

    th, td {
        @apply px-6 py-3
    }

    th {
        @apply py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider;
    }

    td {
        @apply py-4 whitespace-nowrap text-sm leading-5 text-gray-500;
    }

    tbody {
        @apply bg-white;

        > tr + tr {
            border-top-width: 1px;
        }
    }
}

.data-table {
    @apply w-full max-w-full mb-4 text-sm border-gray-200 border;

    thead {
        th {
            @apply bg-gray-50 border-gray-200;
        }
    }

    tbody {
        @apply bg-white;

        th {
            @apply border-b border-gray-100;
        }
    }

    th, td {
        @apply px-3 py-2;

        &.thumbnail {
            @apply hidden;
        }
    }

    th {
        @apply text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider;
    }

    td {
        @apply text-sm leading-5 text-gray-900 border-b border-gray-100;
    }

    @screen lg {
        @apply table-fixed;

        th, td {
            &.thumbnail {
                @apply table-cell;
            }
        }

        th {
            &.bytes, &.ms, &.timespanMs {
                width: 15%;
            }

            &.thumbnail {
                @apply w-40;
            }
        }

        td.url {
            @apply truncate;
        }
    }
}

.table-wrap {
    @apply max-w-full overflow-x-auto mb-4 border border-gray-200;

    .data-table {
        @apply mb-0 border-none;
    }

    @screen sm {
        @apply rounded-lg;
    }
}

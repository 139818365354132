/**
 * Styles specific to the scan results page.
 */

.scan-results-heading {
    @apply mb-2 text-center;

    a {
        @apply block truncate;
    }
}

.scan-results-meta {
    @apply text-sm text-gray-700;
}

.scan-results {
    &.loading {
        @apply flex bg-center bg-no-repeat;

        min-height: 12rem;
        background-image: url('../img/puff.svg');
    }

    .loading-text {
        @apply flex-auto self-end text-center text-gray-500 font-medium;
    }
}

.report-summary {
    @apply grid gap-8;

    grid-template-columns: calc(2 * theme('fontSize.xl')) auto;

    .letter-grade {
        @apply text-xl;
    }

    p {
        @apply mb-4;
    }

    @screen sm {
        grid-template-columns: calc(2 * theme('fontSize.3xl')) auto;

        .letter-grade {
            @apply text-3xl;
        }
    }

    @screen md {
        grid-template-columns: calc(2 * theme('fontSize.4xl')) auto;

        .letter-grade {
            @apply text-4xl;
        }
    }
}

/*
 * Letter grades.
 */
.letter-grade {
    @apply flex flex-col items-center;

    .letter-grade-letter {
        @apply flex-none mb-2;
    }

    .percentage {
        @apply inline-block text-base text-gray-500;
    }
}

.letter-grade-letter {
    @apply inline-block text-center text-white font-semibold rounded-md bg-gray-500;

    width: 2em;
    height: 2em;
    line-height: 2em;

    &.grade-a-plus,
    &.grade-a,
    &.grade-a-minus,
    &.grade-b-plus {
        @apply bg-green-500;
    }

    &.grade-b,
    &.grade-b-minus,
    &.grade-c-plus,
    &.grade-c,
    &.grade-c-minus {
        @apply bg-yellow-500;
    }

    &.grade-d-plus,
    &.grade-d,
    &.grade-d-minus,
    &.grade-f {
        @apply bg-red-500;
    }
}

/*
 * Individual sections of a report.
 */
.report-section {
    @apply my-12;

    h2 {
        @apply mb-2 text-2xl font-bold leading-tight;

        @screen md {
            @apply text-3xl;
        }

        + p {
            @apply mb-8 text-gray-500;
        }
    }

    .card + .card {
        @apply mt-8;
    }
}

/*
 * Individual audits.
 */
.audit-details {
    @screen md {
        @apply grid gap-8;

        grid-template-columns: 40% auto;
    }

    @screen lg {
        grid-template-columns: 20rem auto;
    }
}

.audit-name {
    @apply flex items-center justify-between flex-nowrap mb-6 text-2xl;

    h3 {
        @apply leading-tight font-medium text-gray-900;
    }

    .layer {
        @apply text-gray-400;

        svg {
            fill: currentColor;
            width: 1em;
            height: 1em;
        }
    }
}

.audit-stats {
    @apply mb-6 text-sm leading-6;

    @screen md {
        @apply mb-0;
    }

    > div {
        @apply grid grid-cols-2 gap-2;

        &:first-child {
            @apply pt-1 border-t;
        }

        &:last-child {
            @apply pb-1 border-b;
        }

        .audit.metric & {
            grid-template-columns: 85px 1fr;
        }
    }

    dt {
        @apply text-xs uppercase;
    }
}

/* Priority levels. */
.audit-priority-level {
    @apply uppercase font-bold;

    &.audit-priority-level-1 {
        @apply text-red-500;
    }
    &.audit-priority-level-2 {
        @apply text-yellow-500;
    }
    &.audit-priority-level-3 {
        @apply text-green-500;
    }
}

.audit-recommendations {
    @apply mt-2 leading-relaxed;

    .recommendations-heading {
        @apply font-bold mb-3;
    }

    ul {
        @apply list-disc pl-4 mb-6;
    }

    .learn-more {
        @apply text-sm;
    }

    @screen md {
        @apply mt-0;
    }
}

.audit-description {
    @apply text-base leading-6 text-gray-700 mb-6;
}

.scan-metadata {
    @apply text-sm leading-5;

    > div {
        @apply px-4 py-5 bg-white;

        @screen sm {
            @apply grid grid-cols-3 gap-4 px-6;
        }

        .card &:first-child {
            @apply rounded-t-lg;
        }

        .card &:last-child {
            @apply rounded-b-lg;
        }
    }

    > div:nth-child(odd) {
        @apply bg-gray-50;
    }

    dd {
        @apply mt-1;

        @screen sm {
            @apply mt-0 col-span-2;
        }
    }

    .table-wrap {
        @apply mb-0;
    }

    .data-table {
        @apply table-auto;
    }
}

/*
 * Specialized result formatting.
 */
.source-location {
    .location {
        @apply text-xs text-gray-600;
    }
}
